<template>
    <div class="methodology">
      <div class="info-card methodology-card">
        <h2><i class="fas fa-calculator"></i> Methodology</h2>
        <p class="methodology-info">
          This page explains the methodology used to calculate the yearly carbon emissions.
        </p>
        <div class="methodology-steps">
          <h3><i class="fas fa-gas-pump"></i> Average Daily Fuel Consumption:</h3>
          <ul>
            <li><b>Gasoline:</b> 504,373 liters</li>
            <li><b>Diesel:</b> 33,567 liters</li>
          </ul>
          <h3><i class="fas fa-calendar-alt"></i> Emission Factors by Day:</h3>
          <ul class="day-factors">
            <li><b>Monday:</b> 1</li>
            <li><b>Tuesday:</b> 1.08</li>
            <li><b>Wednesday:</b> 0.5</li>
            <li><b>Thursday:</b> 1.14</li>
            <li><b>Friday:</b> 0.95</li>
            <li><b>Saturday:</b> 1</li>
            <li><b>Sunday:</b> 0.8</li>
          </ul>
          <h3><i class="fas fa-cloud"></i> Carbon Emission Factors:</h3>
          <ul>
            <li><b>Gasoline:</b> 2.419 kg CO₂e per liter</li>
            <li><b>Diesel:</b> 2.735 kg CO₂e per liter</li>
          </ul>
          <h3><i class="fas fa-chart-line"></i> Calculation Method:</h3>
          <p>
            For each day of the year, we multiply the daily fuel consumption by the respective day's emission factor and the carbon emission factor. Summing these daily emissions and converting from kilograms to tons gives the total yearly carbon emissions. This method accounts for daily consumption variations, providing an accurate yearly estimate.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Methodology",
    data() {
      return {
        averageDailyGasolineConsumption: 504373,
        averageDailyDieselConsumption: 33567,
        dayFactors: {
          'Monday': 1,
          'Tuesday': 1.08,
          'Wednesday': 0.5,
          'Thursday': 1.14,
          'Friday': 0.95,
          'Saturday': 1,
          'Sunday': 0.8,
        },
        carbonEmissionFactorGasoline: 2.419,
        carbonEmissionFactorDiesel: 2.735,
      };
    },
  };
  </script>
  
  <style scoped>
  .methodology {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #f7f7f7;
  }
  
  .info-card {
    width: 80%;
    max-width: 800px;
    padding: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .methodology-card {
    border-left: 8px solid #4caf50;
  }
  
  .methodology-info {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    color: #555;
  }
  
  .methodology-steps {
    margin-top: 20px;
  }
  
  .methodology-steps h3 {
    font-size: 24px;
    color: #333;
    margin-top: 20px;
    border-bottom: 2px solid #4caf50;
    padding-bottom: 5px;
  }
  
  .methodology-steps ul {
    list-style-type: none;
    padding-left: 0;
    margin: 15px 0;
  }
  
  .methodology-steps ul li {
    font-size: 18px;
    margin: 10px 0;
    color: #666;
  }
  
  .methodology-steps ul.day-factors li {
    display: flex;
    justify-content: space-between;
  }
  
  .methodology-steps p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-top: 10px;
  }
  
  /* Import FontAwesome styles */
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css');
  </style>
  